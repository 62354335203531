<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="600"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                <v-card 
                    flat
                >
                    <v-card-text>
                        <v-row no-gutters>

                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">
                                    {{ $t("Решение") }}
                                </label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">

                                <div class="icon-click-row-group">
                                    <div class="onlyReadData more-per-lab-wrap">
                                        <v-chip-group
                                            v-model="decision"
                                            class="modal-chips-panel"
                                            column
                                        >

                                            <v-chip
                                                v-for="decision in decisions"
                                                :value="decision.code"
                                                :key="decision.code"
                                                :active-class="decisionClass"
                                                label
                                                small
                                            >
                                                {{ $t(decision.value) }}
                                            </v-chip>

                                        </v-chip-group>
                                    </div>
                                </div>
                            </v-col>

                        </v-row>

                        <v-row v-if="decision === 4 || decision === 3" no-gutters>

                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">
                                    {{ $t("Комментарий") }}
                                </label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <v-textarea
                                    v-model="comment"
                                    rows="3"
                                    no-resize
                                    hide-details
                                    required
                                    outlined
                                    dense
                                >
                                </v-textarea>
                            </v-col>

                        </v-row>

                        <v-row v-if="decision === 4" no-gutters>

                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">
                                    {{ $t("Вложение") }}
                                </label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <div class="icon-click-row-group">
                                    <div @click="$refs.fileInputRef.click()">
                                        <input
                                            type="file"
                                            ref="fileInputRef"
                                            @change="onFileChanged"
                                            style="display: none"
                                        />
                                        <v-icon small>
                                            fas fa-edit
                                        </v-icon>
                                    </div>

                                    <div class="cloud-file-list-box">
                                        <div class="cloud-items-container">

                                            <div v-if="!attachment" class="cfl-placeholder">{{ $t('Нет_файлов') }}</div>

                                            <v-row 
                                                v-else
                                                dense
                                            >
                                                <v-file-item
                                                    :name="attachment.name"
                                                    :size="attachment.size"
                                                    col-width="12"
                                                    :hide-load="true"
                                                    @on-delete-attachment="onDeleteAttachment"
                                                />
                                            </v-row>

                                        </div>
                                    </div>
                                </div>
                            </v-col>

                        </v-row>

                        <v-divider class="marg-b-10"></v-divider>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="12">
                                <div class="onlyReadData">
                                    <v-checkbox
                                        v-model="redirect"
                                        :label="$t('Перейти_к_списку_документов_на_согласовании')"
                                        color="primary"
                                        hide-details
                                        dense
                                    ></v-checkbox>
                                </div>
                            </v-col>
                        </v-row>

                    </v-card-text>

                </v-card>

            </v-card-text>

            <v-card-actions>

                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok(redirect)"
                    v-if="decision !== null"
                >
                    {{ $t(okText) }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/i18n'

export default {
    name: "SetAdjustmentDlg",
    decisionClasses: {
        2: "teal",
        4: "orange",
        3: "red"
    },
    data () {
        return {
            title: "Согласование",
            redirect: true,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            }
        }
    },
    computed: {
        ...mapGetters('global/dialogs/setAdjustment',
        { 
            visible: 'isVisible',
            decisions: 'getDecisions',
            attachment: 'getAttachment'
        }),
        decision: {
            get: function() {
                return this.$store.getters['global/dialogs/setAdjustment/getDecision'];
            },
            set: function(v) {
                this.$store.dispatch('global/dialogs/setAdjustment/changeDecision', v);
            }
        },
        comment: {
            get: function() {
                return this.$store.getters['global/dialogs/setAdjustment/getComment'];
            },
            set: function(v) {
                this.$store.commit('global/dialogs/setAdjustment/SET_COMMENT', v);
            }
        },
        decisionClass() {
            return this.$options.decisionClasses[this.decision];
        },
        selectAttachmentTooltip() {
            return i18n.t("Выбрать_вложение");
        },
        okText() {
            return this.decisions.find(x => x.code === this.decision)?.value;
        }
    },
    methods: {
        ...mapActions('global/dialogs/setAdjustment', ['ok', 'cancel']),
        ...mapActions('global/attachments', ['validateFile']),
        onFileChanged(event) {
            let file = Array.from(event.target.files)?.[0];

            if (file) {

                this.validateFile(file)
                    .then(checkResult => {
                        
                        if (!checkResult.success) {
                            this.$notify.alert(i18n.t('Ошибка_загрузки_файла:_filename_with_reason.message', { filename: file.name, reason: checkResult.message }));
                            this.$store.commit('global/dialogs/setAdjustment/SET_ATTACHMENT', null);
                            event.target.value = '';
                            return;
                        }

                        this.$store.commit('global/dialogs/setAdjustment/SET_ATTACHMENT', file);
                    });
            }

            event.target.value = '';
        },
        onDeleteAttachment(){
            this.$store.commit('global/dialogs/setAdjustment/SET_ATTACHMENT', null);
        }
    }
}
</script>